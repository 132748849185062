import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import {defaultFilters} from '@/models/BudgetReport/helpers'
import budgetReportRepository from '@/repository/generatedRepository/budgetReportRepository'
import TableOptions from '@/models/TableOptions'
import {getSortedItems, prepareFilters} from '@/helpers/filters'
import partnerBudgetRepository from "@/repository/generatedRepository/partnerBudgetRepository";
import {generatePassword} from "@/helpers/functions";
import {omit} from "lodash";
import i18n from "@/plugins/vue-i18n";
import moment from "moment";
import {canFitInLocalStorage} from "@/helpers/general-report";


const state = {
  list: [],
  notUsedSpend: [],
  total: {},
  filters: StorageService.get(storageKeys.BUDGET_REPORT_FILTERS) || {...defaultFilters},
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => [...state.list, ...state.notUsedSpend],
  total: state => state.total,
  notUsedSpend: state => state.notUsedSpend,
  listPrepared: state =>
    [...getSortedItems(state.list, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }, 'budgetReport'), ...state.notUsedSpend],
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {updateData = true, signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.BUDGET_REPORT_FILTERS, filters)

    const preparedFilters = prepareFilters({
      ...omit(filters, ['sortBy', 'sortDesc', 'pagination']),
      signal
    })
    let data = null
    if (StorageService.get(storageKeys.BUDGET_REPORT_DATA) && !updateData) {
      // if (!updateData || updateData) {
      data = StorageService.get(storageKeys.BUDGET_REPORT_DATA)
      data.rows.forEach(r => {
        r.groups.forEach(group => {
          group.budget_value = +group?.budget?.budget || 0
        })
      })
    } else {
      data = (await budgetReportRepository.post(preparedFilters)).data
      data.rows.forEach(item => {
        item.groups.forEach(el => {
          el.budget_value = el.budget?.budget ? +el.budget.budget : 0
          el.free_budget = el.budget_value - el.actual_budget
        })
        item.budget = item.groups.reduce((acc, el) => {
          acc += +el.budget?.budget ? +el.budget.budget : 0
          return acc
        }, 0)
        item.free_budget = item.groups.reduce((acc, el) => {
          acc += el.free_budget
          return acc
        }, 0)
        item.budget_value = item.budget
      })
      data.rows.forEach((item) => {
        const result = []
        item.id = generatePassword(8)
        item.groups.sort((a, b) => b.actual_budget - a.actual_budget)
        item.groups.forEach(group => {
          // let subResult = []
          group.id = generatePassword(8)
          result.push(group)
          group.partnersPrep = []
          Object.values(group.partners).forEach(partner => {
            group.partnersPrep.push(({budget: partner.partner, ...partner}))
          })
        })
        item.groups = result
      })
      data.total.budget = data.rows.reduce((acc, el) => {
        acc += +el.budget
        return acc
      }, 0)
      data.total.free_budget = data.rows.reduce((acc, el) => {
        acc += el.free_budget
        return acc
      }, 0)
      data.total.actual_budget = data.total.total

      if (canFitInLocalStorage(data, 1)) {
        StorageService.set(storageKeys.BUDGET_REPORT_DATA, data)
      } else {
        StorageService.remove(storageKeys.BUDGET_REPORT_DATA)
      }
    }
    context.commit('setList', {data, filters})

    return {products: data.rows, pagination: data.pagination}
  },
  async sortBudgetReport(context, filters) {
    StorageService.set(storageKeys.BUDGET_REPORT_FILTERS, filters)
    context.commit('setTableOptions', filters)
  },
  async loadItem(context, id) {
    const {data} = await budgetReportRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return budgetReportRepository.post(payload)
  },
  createBudget(context, payload) {
    return partnerBudgetRepository.post(payload)
  },
  updateItem(context, payload) {
    const {id, ...data} = payload
    return budgetReportRepository.put(id, data)
  },
  updateBudget(context, payload) {
    const {id, ...data} = payload
    return partnerBudgetRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return budgetReportRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.BUDGET_REPORT_FILTERS)
  },
  updateFiltersClientOptions({commit, getters}, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.BUDGET_REPORT_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, {data, filters}) {
    const convObj = {
      title: i18n.tc('generalReport.table.notUsedSpend'),
      actual_budget: 0
    }
    if (data?.not_used_spend && Object.values(data.not_used_spend).length) {
      convObj.groups = Object.values(data.not_used_spend).map(item => {
        item.budget = item.country + ', ' + item.partner + ', ' + item.stream + ', ' + moment(item.date).format('DD.MM.YYYY')
        item.actual_budget = item.price
        convObj.actual_budget += +item.price
        item.free_budget = ''
        item.country = ''
        return item
      })
      state.notUsedSpend = [convObj]
    }
    data.total.actual_budget += convObj.actual_budget
    state.list = data.rows
    state.total = data.total
    state.tableOptions.sortBy = filters.sortBy
    state.tableOptions.sortDesc = filters.sortDesc
  },
  updateList(state) {
    StorageService.set(storageKeys.BUDGET_REPORT_DATA, {
      rows: state.list,
      total: state.total
    })
  },
  setFilters(state, filters) {
    state.filters = {...filters}
  },
  setTableOptions(state, filters) {
    state.tableOptions.sortBy = filters.sortBy
    state.tableOptions.sortDesc = filters.sortDesc
    state.filters.sortBy = filters.sortBy
    state.filters.sortDesc = filters.sortDesc
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
