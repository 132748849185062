import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import {defaultFilters} from '@/models/CohortAnalysis/helpers'
import TableOptions from '@/models/TableOptions'
import {getPaginatedList, getSortedItems, mockPagination, omitEmptyDeepFilters, prepareFilters} from '@/helpers/filters'
import playerReportsRepository from "@/repository/generatedRepository/playerReportsRepository";
import i18n from "@/plugins/vue-i18n";
import moment from "moment/moment";
import {generatePassword} from "@/helpers/functions";
import store from "@/store";
import {WIDGET_REPORTS} from "@/constants/widgetReports";
import {DATE_TIME_FORM_SHORT_FORMAT_DAY_FIRST} from "@/constants/date";

function defineGroupId(group) {
  switch (group) {
    case 'traffic_source':
      return 'traffic'
    case 'actual_country':
      return 'geo_country'
    case 'site_id_sub_id':
      return 'site_id'
    case 'click_id':
      return 'player_id'
    case 'site_id':
      return 'advertiser'
    case 'main_manager':
      return 'manager_name'
    default:
      return group
  }
}

function canFitInLocalStorage(data) {
  try {
    const jsonData = JSON.stringify(data);
    const jsonDataSize = jsonData.length * 2; // data size (bytes)
    return jsonDataSize < 1.5 * 1024 * 1024 // 1.5MB limit
  } catch (e) {
    return false;
  }
}

const state = {
  list: [],
  paginatedList: [],
  notUsedSpend: [],
  total: {},
  playersCount: 0,
  filters: StorageService.get(storageKeys.COHORT_ANALYSIS_FILTERS) || {...defaultFilters},
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  total: state => state.total,
  list: state => state.list,
  playersCount: state => state.playersCount,
  notUsedSpend: state => state.notUsedSpend,
  paginatedList: state => state.paginatedList,
  listPrepared: state =>
    [...getSortedItems(state.list, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }), ...state.notUsedSpend],
  listPaginated: state =>
    [...getPaginatedList(state.paginatedList, state.tableOptions.pagination, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }), ...(state.tableOptions.page === state.tableOptions.totalItems ? state.notUsedSpend : [])],
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filtersData) {
    const {updateData = true, signal, ...filters} = filtersData
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    let data = {}
    if (StorageService.get(storageKeys.COHORT_ANALYSIS_DATA) && !updateData) {
      data = StorageService.get(storageKeys.COHORT_ANALYSIS_DATA)
    } else {
      const result = await store.dispatch('ReportNotificationModule/loadNotification')
      const reportCacheUpdated = Object.keys(result.reportNotifications).every(v => !result.reportNotifications[v])
      await store.dispatch('ReportNotificationModule/updateLocalStorage', {
        reportName: WIDGET_REPORTS.COHORT_ANALYSIS,
        reportCacheState: result.reportNotifications,
        reportBuiltAt: moment().format(DATE_TIME_FORM_SHORT_FORMAT_DAY_FIRST),
        reportCacheUpdated,
        ...(reportCacheUpdated ? {widget2_closed: true} : {})
      })
      data = (await playerReportsRepository.cohortAnalysis({
        ...preparedFilters.filter, signal})).data
    }
    if (canFitInLocalStorage(data)) {
      StorageService.set(storageKeys.COHORT_ANALYSIS_DATA, data)
    } else {
      StorageService.remove(storageKeys.COHORT_ANALYSIS_DATA)
    }
    context.commit('setList', {
      data,
      filters
    })
    context.commit('setPaginatedList', {
      data,
      pagination: mockPagination(data.rows, filters),
      filters
    })
    return {products: data.items}
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.COHORT_ANALYSIS_FILTERS)
  },
  updateFiltersClientOptions({commit, getters}, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, updatedFilters)
  },
  updatePaginationGeneralReport(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, filters)
    context.commit('setPagination', filters.pagination)
  },
  updateSortGeneralReport(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, filters)
    context.commit('setSort', {
      sortBy: filters.sortBy,
      sortDesc: filters.sortDesc,
    })
  }
}

const mutations = {
  setList(state, {data, pagination, filters}) {
    if (data === null) return state.list = []
    let convArr = null
    const firstGroupBy = filters.filter.groupBy
    const secondGroupBy = filters.filter.secondGroupBy || 'not_used_spend'
    if (Object.values(data.not_used_spend).length === 1 && !filters.filter.secondGroupBy) {
      let item = Object.values(data.not_used_spend)[0]
      const resString = 'NUS: ' + item.country + ', ' + item.partner + ', ' + item.stream + ', ' + moment(item.date).format('DD.MM.YYYY')
      item.groups = {
        [defineGroupId(firstGroupBy)]: i18n.tc('generalReport.table.notUsedSpend'),
      }
      item.second_group = []
      item.spend = item.price
      item.spend_2 = item.price_2
      item.new_deposits = 'empty'
      if (secondGroupBy === 'not_used_spend') {
        item.not_used_spend = resString
      } else {
        item.groups[defineGroupId(secondGroupBy)] = resString
      }
      convArr = item
    } else {
      convArr = {
        groups: {[defineGroupId(firstGroupBy)]: i18n.tc('generalReport.table.notUsedSpend')},
      }
      convArr.second_group = Object.values(data.not_used_spend).map(item => {
        const resString = 'NUS: ' + item.country + ', ' + item.partner + ', ' + item.stream + ', ' + moment(item.date).format('DD.MM.YYYY')
        item.groups = {
          [defineGroupId(secondGroupBy)]: null
        }
        item.spend = item.price
        item.spend_2 = item.price_2
        item.new_deposits = 'empty'
        if (secondGroupBy === 'not_used_spend') {
          item.not_used_spend = resString
        } else {
          item.groups[defineGroupId(secondGroupBy)] = resString
        }
        return item
      })
      convArr.spend = Object.values(data.not_used_spend).reduce((acc, item) => {
        acc += +item.price
        return acc
      }, 0)
      convArr.spend_2 = Object.values(data.not_used_spend).reduce((acc, item) => {
        acc += +item.price_2
        return acc
      }, 0)
      convArr.new_deposits = 'empty'
    }
    data.rows.forEach(row => { // make empty fields for multi group
      row.id = generatePassword(8)
      row.groups[defineGroupId(filters.filter.secondGroupBy)] = null
      row.groups[defineGroupId(filters.filter.thirdGroupBy)] = null
      row.groups[defineGroupId(filters.filter.fourthGroupBy)] = null
      if ([
        defineGroupId(filters.filter.secondGroupBy),
        defineGroupId(filters.filter.thirdGroupBy),
        defineGroupId(filters.filter.fourthGroupBy),
      ].includes('player_id')) row.groups.click_id = null
      if (filters.filter.groupBy === 'promocode') {
        row.groups.promocode = row.groups.promocode || 'No promocode'
      }
      if (filters.filter.groupBy === 'month') {
        row.groups.month = `${row.groups.month_title || ''} ${row.groups.year || ''}`
      }
      if (filters.filter.groupBy === 'main_manager') {
        row.groups.manager_name += ` ${row.groups?.manager_last_name || ''}`
        Object.values(row.second_group).forEach(value => {
          value.groups.manager_name += ` ${value.groups?.manager_last_name || ''}`
        })
      }
      if (filters.filter.groupBy === 'offer') {
        row.groups.offer = `${row.groups.offer || ''} ${row.groups.advertiser ? `, ${row.groups.advertiser}` : ''}`
      }
      if (filters.filter.secondGroupBy) row.second_group.forEach(subRow => {
        subRow.id = generatePassword(8)
        Object.keys(subRow.groups).forEach(key => {
          if (['offer_tag', 'partner_tag'].includes(key)) return
          if (!subRow.groups[key] && !key.includes('_id')) {
            subRow.groups[key] = '<not selected>'
            subRow.groups[key + '_id'] = null
          }
        })
        subRow.groups[defineGroupId(filters.filter.groupBy)] = null
        subRow.groups[defineGroupId(filters.filter.thirdGroupBy)] = null
        subRow.groups[defineGroupId(filters.filter.fourthGroupBy)] = null
        if (filters.filter.secondGroupBy === 'site_id_sub_id')
          subRow.groups.site_id_sub_id = `${subRow.groups.site_id} (${subRow.groups.sub_id || '-'})`
        if (filters.filter.secondGroupBy === 'click_id') {
          subRow.click_count = ''
          subRow.click_reg = ''
        }
        if (filters.filter.secondGroupBy === 'month')
          subRow.groups.month = `${subRow.groups.month_title} ${subRow.groups.year}`
        if (filters.filter.secondGroupBy === 'offer') {
          subRow.groups.offer = `${subRow.groups.offer || ''} ${subRow.groups.advertiser ? `, ${subRow.groups.advertiser}` : ''}`
        }
        if ([
          defineGroupId(filters.filter.thirdGroupBy),
          defineGroupId(filters.filter.fourthGroupBy),
        ].includes('player_id')) subRow.groups.click_id = null
        if (filters.filter.thirdGroupBy) subRow.third_group.forEach(item => {
          item.id = generatePassword(8)
          Object.keys(item.groups).forEach(key => {
            if (['offer_tag', 'partner_tag'].includes(key)) return
            if (!item.groups[key]) {
              item.groups[key] = '<not selected>'
              item.groups[key + (key === 'traffic' ? '_source_id' : '_id')] = null
            }
          })
          item.groups[defineGroupId(filters.filter.groupBy)] = null
          item.groups[defineGroupId(filters.filter.secondGroupBy)] = null
          item.groups[defineGroupId(filters.filter.fourthGroupBy)] = null
          if (filters.filter.thirdGroupBy === 'site_id_sub_id')
            item.groups.site_id_sub_id = `${item.groups.site_id} (${item.groups.sub_id || '-'})`
          if (filters.filter.thirdGroupBy === 'click_id') {
            item.click_count = ''
            item.click_reg = ''
          }
          if (filters.filter.thirdGroupBy === 'month')
            item.groups.month = `${item.groups.month_title} ${item.groups.year}`
          if (filters.filter.thirdGroupBy === 'offer') {
            item.groups.offer = `${item.groups.offer || ''} ${item.groups.advertiser ? `, ${item.groups.advertiser}` : ''}`
          }
          if ([
            defineGroupId(filters.filter.fourthGroupBy)
          ].includes('player_id')) item.groups.click_id = null
          if (filters.filter.fourthGroupBy) item.fourth_group.forEach(subItem => {
            subItem.id = generatePassword(8)
            Object.keys(subItem.groups).forEach(key => {
              if (['offer_tag', 'partner_tag'].includes(key)) return
              if (!subItem.groups[key]) {
                subItem.groups[key] = '<not selected>'
                subItem.groups[key + (key === 'traffic' ? '_source_id' : '_id')] = null
              }
            })
            subItem.groups[defineGroupId(filters.filter.groupBy)] = null
            subItem.groups[defineGroupId(filters.filter.secondGroupBy)] = null
            subItem.groups[defineGroupId(filters.filter.thirdGroupBy)] = null
            if (filters.filter.fourthGroupBy === 'site_id_sub_id')
              subItem.groups.site_id_sub_id = `${subItem.groups.site_id} (${subItem.groups.sub_id || '-'})`
            if (filters.filter.fourthGroupBy === 'click_id') {
              subItem.click_count = ''
              subItem.click_reg = ''
            }
            if (filters.filter.fourthGroupBy === 'month')
              subItem.groups.month = `${subItem.groups.month_title} ${subItem.groups.year}`
            if (filters.filter.fourthGroupBy === 'offer') {
              subItem.groups.offer = `${subItem.groups.offer || ''} ${subItem.groups.advertiser ? `, ${subItem.groups.advertiser}` : ''}`
            }
          })
        })
      })
    })
    state.list = [...data.rows]
    state.notUsedSpend = Object.values(data.not_used_spend).length ? [convArr] : []
    state.total = data.total
    state.playersCount = data.player_count
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setPaginatedList(state, {data, pagination, filters}) {
    state.paginatedList = [...data.rows]
    state.total = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = {...filters}
  },
  setPagination(state, pagination) {
    state.tableOptions = {
      ...state.tableOptions,
      page: pagination.page,
      pagination,
      totalItems: Math.ceil(state.tableOptions.total / pagination.limit),
    }
  },
  setSort(state, {sortBy, sortDesc}) {
    state.tableOptions = {
      ...state.tableOptions,
      sortBy,
      sortDesc,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
