import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/NewStreams/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters } from '@/helpers/filters'
import partnerReportRepository from "@/repository/generatedRepository/partnerReportRepository";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.NEW_STREAMS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.NEW_STREAMS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const { data } = await partnerReportRepository.streamReport(appliedFilters)
    context.commit('setList', { data })

    return { items: data }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.NEW_STREAMS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.NEW_STREAMS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data }) {
    state.list = [
      ...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data,
      ...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data,
    ]
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
