import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/PartnerReport/helpers'
import partnerReportRepository from '@/repository/generatedRepository/partnerReportRepository'
import TableOptions from '@/models/TableOptions'
import { prepareFilters } from '@/helpers/filters'

const state = {
  list: [],
  total: 0,
  filters: StorageService.get(storageKeys.PARTNER_REPORT_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  total: state => state.total,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.PARTNER_REPORT_FILTERS, filters)

    const preparedFilters = prepareFilters({
      ...filters,
      signal
    })

    const { data } = await partnerReportRepository.newDeposits(preparedFilters)
    data.rows.forEach(item => {
      item.preparedGroups = []
      Object.keys(item.groups).forEach(group => {
        const offers = 'offers' in item.groups[group] ?
          actions.changeOffersObj(item, group) : {...item.groups[group]}
        item.preparedGroups.push(item.groups[group], ...offers)
      })
    })

    context.commit('setList', { data, filters })

    return { products: data.rows, pagination: data.pagination }
  },
  changeOffersObj(item, group){
    return Object.values(item.groups[group].offers).map(el => {
      el.offer = el.title
      el.title = ''
      return el
    })
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.PARTNER_REPORT_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.PARTNER_REPORT_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...Object.values(data.rows)]
    state.total = data.total
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
